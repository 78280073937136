import axios from "axios";
import authAxiosInstance from "../lib/interceptors/apiInterceptor";
import authGqlAxiosInstance from "../lib/interceptors/gqlApiInterceptor";
import httpToastService from "../lib/interceptors/http-toast-interceptor";
import { removeEmptyKeys } from "../lib/utils/objectFunctions";
import { useUser } from "@auth0/nextjs-auth0/client";

axios.defaults.baseURL = `${process.env.NEXT_PUBLIC_SERVER_API_URL}`;

class OrganizationService {
  async doesOrganizationExist({
    loginName,
  }: {
    loginName: string;
  }): Promise<any> {
    try {
      const response = await axios.post(
        `/apiv1/organization/existsCheck/${loginName}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addUserToOrganization(data: any): Promise<any> {
    try {
      const response = await axios.post(
        "/apiv1/organization/associateUser",
        {
          user_id: data.user_id,
          org_id: data.org_id,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async inviteUserToOrganization(
    data: {
      name: string;
      email: string;
      org_id: string;
    },
    headers?: any
  ): Promise<any> {
    try {
      const response = await authAxiosInstance.post(
        `/organization/${data.org_id}/invite`,
        {
          name: data.name,
          email: data.email,
          org_id: data.org_id,
        },
        {
          headers: {
            ...headers,
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getAuth0Users(organizationId: string): Promise<any> {
    try {
      const response = await authAxiosInstance.get(
        `/organization/${organizationId}/users`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getInvites(data: { org_id: string }): Promise<any> {
    try {
      const response = await authAxiosInstance.get(
        `/organization/${data.org_id}/invitations`,
        {
          params: {
            org_id: data.org_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateAuth0Organization({
    organizationId,
    data,
  }: {
    organizationId: string;
    data: any;
  }): Promise<any> {
    // Clean up the data to remove empty keys
    const cleanedData = removeEmptyKeys(data);

    try {
      const response = await authAxiosInstance.patch(
        `/organization/${organizationId}/updateAuth0`,
        {
          ...cleanedData,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateOrganization(organizationId: string, data: any): Promise<any> {
    try {
      console.log("Data: ", data);
      const response = await authAxiosInstance.patch(
        `/organization/${organizationId}/update`,
        { ...data },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getOrganizationProfile(organizationId: string): Promise<any> {
    try {
      // const response = await authAxiosInstance.get(
      //   `/organization/${organizationId}/profile`
      // );
      // return response.data;
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `query getPortfolioByOrgId($orgId: ID!) {
            getPortfolioByOrgId(orgId: $orgId) {
              overallRiskSummaries {
                date
                low
                medium
                high
                averageCreditPulseScore
                lowCreditExtended
                lowCreditUtilized
                mediumCreditExtended
                mediumCreditUtilized
                highCreditExtended
                highCreditUtilized
                averageDbt
                industryDbt
              }
              currentRiskExposureByIndustry {
                industry
                totalAccounts
                low
                medium
                high
                averageCreditPulseScore
                totalCreditUtilized
                totalCreditExtended
                industryDbt
                averageDbt
              }
            }
          }`,
          operationName: 'getPortfolioByOrgId',
          variables: {
            orgId: organizationId,
          },
        },
      });
      return response?.data?.data?.getPortfolioByOrgId;
    } catch (error) {
      throw error;
    }
  }

  async getOrganizationAuth0Data(organizationId: string): Promise<any> {
    try {
      const response = await authAxiosInstance.get(
        `/organization/${organizationId}/auth0profile`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Delete both the Auth0 organization and the organization in the Swyft database
  async deleteOrganization(organizationId: string): Promise<any> {
    try {
      // First delete operation
      const deleteOrganization = await axios.delete(
        `/apiv1/organization/${organizationId}`
      );

      // Second delete operation
      const deleteAuth0Organization = await axios.delete(
        `/apiv1/organization/auth0/${organizationId}`
      );

      return {
        response1: deleteOrganization.data,
        response2: deleteAuth0Organization.data,
      };
    } catch (error) {
      // Handle errors from either operation
      throw error;
    }
  }

  async getClientsByOrganizationId(organizationId: string, groupIds?: string[]): Promise<any> {
    try {
      // const response = await authAxiosInstance.get(
      //   `/organization/${organizationId}/clients`
      // );
      // return response.data;
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `query getAccountsByOrgIdAndGroupIds($orgId: ID!, $groupIds:[ID!], $skip: Int, $limit: Int) {
            getAccountsByOrgIdAndGroupIds(orgId: $orgId, groupIds: $groupIds, skip: $skip, limit: $limit) {
              id
              groupId
              company {
                id
                name
                industry
                status
              }
              latestPulseScores {
                creditPulse
              }
              latestCreditsafeReport {
                report {
                  paymentData {
                    dbt {
                      dbt
                      industryDBT
                    }
                  }
                }
              }
              isFavorite
              creditExtended
              creditUsed
            }
          }`,
          operationName: 'getAccountsByOrgIdAndGroupIds',
          variables: {
            orgId: organizationId,
            groupIds: (groupIds?.length) ? groupIds : undefined,
          },
        },
      });
      return response?.data?.data?.getAccountsByOrgIdAndGroupIds;
    } catch (error) {
      throw error;
    }
  }

  async getGroupsByOrganizationId(organizationId: string): Promise<any> {
    try {
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `query getGroupsByOrgId($orgId: ID!) {
            getGroupsByOrgId(orgId: $orgId) {
              groups {
                id
                name
              }
            }
          }`,
          operationName: 'getGroupsByOrgId',
          variables: {
            orgId: organizationId,
          },
        },
      });
      return response?.data?.data?.getGroupsByOrgId?.groups;
    } catch (error) {
      throw error;
    }
  }
}

export default new OrganizationService();
