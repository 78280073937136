import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

export interface IOrganizationProfile {
  overallRiskSummaries: IRiskSummaryItem[];
  currentRiskExposureByIndustry: IIndustryRiskItem[];
}
interface IRiskSummaryItem {
  date: string;
  high: number;
  highCreditUtilized: number;
  highCreditExtended: number;
  medium: number;
  mediumCreditUtilized: number;
  mediumCreditExtended: number;
  low: number;
  lowCreditUtilized: number;
  lowCreditExtended: number;
  averageCreditPulseScore: number;
  averageDbt: number;
  industryDbt: number;
}

interface IIndustryRiskItem {
  averageCreditPulseScore: number;
  totalAccounts: number;
  totalCreditUtilized: number;
  totalCreditExtended: number;
  averageDbt: number;
  industryDbt: number;
  industry: string;
  high: number;
  medium: number;
  low: number;
}

interface OrganizationState {
  organizationProfile: IOrganizationProfile | null;
}

interface OrganizationActions {
  setOrganizationProfile: (profile: IOrganizationProfile) => void;
}

const INITIAL_STATE: OrganizationState = {
  organizationProfile: null,
};

type OrganizationStore = OrganizationState & OrganizationActions;

export const useOrganizationStore = create<OrganizationStore>((set) => {
  let organizationProfile = null;

  // Only attempt to access localStorage when on the client side
  if (typeof window !== "undefined") {
    const savedOrganizationProfile = localStorage.getItem(
      "organizationProfile"
    );
    organizationProfile = savedOrganizationProfile
      ? JSON.parse(savedOrganizationProfile)
      : [];
  }

  return {
    organizationProfile,

    setOrganizationProfile: (profile: IOrganizationProfile) => {
      // Only modify localStorage when on the client side
      if (typeof window !== "undefined") {
        localStorage.setItem("organizationProfile", JSON.stringify(profile));
      }
      set({ organizationProfile: profile });
    },
  };
});

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("OrganizationStore", useOrganizationStore);
}
