export function removeEmptyKeys(obj: any): any {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // Iterate over object keys and remove keys with falsy values or empty objects
  for (const key in obj) {
    const value = obj[key];
    if (value && typeof value === "object") {
      obj[key] = removeEmptyKeys(value); // Recurse for nested objects
    }
    // Remove falsy values and empty objects or arrays
    if (
      value === "" ||
      value == null ||
      (typeof value === "object" && isEmpty(value))
    ) {
      delete obj[key];
    }
  }

  return obj;
}

export function isEmpty(obj: any): boolean {
  // Check if the object has no keys or if it's an empty array
  return (
    (obj.constructor === Object && Object.keys(obj).length === 0) ||
    (obj.constructor === Array && obj.length === 0)
  );
}
