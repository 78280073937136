import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import organizationService from "../services/organizationService";
import userService from "../services/userService";
import { toast } from "react-toastify";
import { useOrganizationStore } from "../stores/organizationStore";
import axios from "axios";
import { useEffect } from "react";
import { useClientStore } from "../stores/clientStore";

const useDoesOrganizationExist = ({ loginName }: { loginName: string }) => {
  const { data, isPending, error, isSuccess, isError } = useQuery({
    queryKey: [],
    queryFn: () => organizationService.doesOrganizationExist({ loginName }),
    staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
    refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
    refetchOnReconnect: true, // refetch when the connection is restored
    refetchOnMount: true, // only refetch when parameters change, not every mount
    retry: 1, // retry failed queries once before throwing an error
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
    enabled: true, // only run the query if connectionId is available
  });
  if (error) {
    console.error("Query Error:", error);
  }
  return {
    data,
    isLoading: isPending,
    isError: isError,
    error: error,
    isSuccess: isSuccess,
  };
};

const useGetAllUsers = (organizationId: string) => {
  const queryCLient = useQueryClient();

  const { data, isPending, error, isSuccess, isError } = useQuery({
    queryKey: ["organizationUsers", organizationId],
    queryFn: () => Promise.resolve([]), // organizationService.getAuth0Users(organizationId),
    staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
    refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
    refetchOnReconnect: true, // refetch when the connection is restored
    refetchOnMount: true, // only refetch when parameters change, not every mount
    retry: 1, // retry failed queries once before throwing an error
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
    enabled: !!organizationId, // only run the query if connectionId is available
  });
  if (error) {
    console.error("Query Error:", error);
  }
  return {
    data,
    isLoading: isPending,
    isError: isError,
    error: error,
    isSuccess: isSuccess,
  };
};

const useInviteUser = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async (data: any) =>
      organizationService.inviteUserToOrganization(data),
    onSuccess: async (data, formVariables) => {
      queryClient.invalidateQueries();

      toast.success("User succesfully invited");
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    createInvitation: mutateAsync,
    isPending: isPending,
    isError: isError,
    error: error,
  };
};

const useGetAllInvitations = (organizationId: string) => {
  const queryClient = useQueryClient();
  const { data, error, isPending, isSuccess, isError } = useQuery({
    queryKey: ["invitesData", organizationId],
    queryFn: () => organizationService.getInvites({ org_id: organizationId }),
    staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
    refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
    refetchOnReconnect: true, // refetch when the connection is restored
    refetchOnMount: true, // only refetch when parameters change, not every mount
    retry: 1, // retry failed queries once before throwing an error
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
    enabled: !!organizationId, // only run the query if connectionId is available
  });
  if (error) {
    console.error("Query Error:", error);
  }
  return {
    data,
    isLoading: isPending,
    isError: isError,
    error: error,
    isSuccess: isSuccess,
  };
};

const useUpdateAuth0Organization = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async ({
      organizationId,
      data,
    }: {
      organizationId: string;
      data: any;
    }) => organizationService.updateAuth0Organization({ organizationId, data }),
    onSuccess: async (data) => {
      console.log("Mutation Success:", data);
      queryClient.invalidateQueries({
        queryKey: ["organizationData", data.id],
      });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });

  return {
    mutateAsync: mutateAsync,
    isLoading: isPending,
    isError: isError,
    error: error,
  };
};

const useGetOrganizationProfile = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const queryClient = useQueryClient();
  const { setOrganizationProfile } = useOrganizationStore();

  const { data, error, isPending, isSuccess } = useQuery({
    queryKey: ["organizationProfile", organizationId],
    queryFn: () => organizationService.getOrganizationProfile(organizationId),
    staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
    refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
    refetchOnReconnect: true, // refetch when the connection is restored
    refetchOnMount: true, // only refetch when parameters change, not every mount
    retry: 1, // retry failed queries once before throwing an error
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
    enabled: !!organizationId, // only run the query if connectionId is available
  });

  useEffect(() => {
    if (isSuccess && data) {
      setOrganizationProfile(data);
    }
  }, [data, isSuccess]);

  if (error) {
    console.error("Query Error:", error);
  }

  return {
    data,
    isLoading: isPending,
    isError: !!error,
    error,
    isSuccess,
  };
};

const useUpdateOrganization = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: async ({ organizationId, data }: any) => {
      return organizationService.updateOrganization(organizationId, data);
    },
    onSuccess: async (data) => {},
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.error("Mutation Error:", error);
      }
    },
  });
  return {
    mutateAsync: mutateAsync,
    isLoading: isPending,
    isError: isError,
    error: error,
  };
};

const useGetOrganizationAuth0Data = (organizationId: string) => {
  const queryClient = useQueryClient();

  const { data, error, isPending, isSuccess, refetch, status } = useQuery({
    queryKey: ["organizationAuth0Data"],
    queryFn: () => organizationService.getOrganizationAuth0Data(organizationId),
    staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
    refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
    refetchOnReconnect: true, // refetch when the connection is restored
    refetchOnMount: true, // only refetch when parameters change, not every mount
    retry: 1, // retry failed queries once before throwing an error
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
    enabled: !!organizationId, // only run the query if connectionId is available
  });
  return {
    data,
    isLoading: isPending,
    isError: !!error,
    error,
    isSuccess,
    refetch,
    status,
  };
};

const useGetClientsByOrganizationId = (organizationId: string, groupIds?: string[]) => {
  const queryClient = useQueryClient();
  const { updateAllMonitorClients } = useClientStore();
  const { data, error, isPending, isSuccess, isError } = useQuery({
    queryKey: ["allClients", organizationId, groupIds],
    queryFn: () =>
      organizationService.getClientsByOrganizationId(organizationId, groupIds),
    staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
    refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
    refetchOnReconnect: true, // refetch when the connection is restored
    refetchOnMount: true, // only refetch when parameters change, not every mount
    retry: 1, // retry failed queries once before throwing an error
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
    enabled: !!organizationId, // only run the query if organizationId is available
  });

  useEffect(() => {
    if (isSuccess && data) {
      updateAllMonitorClients(data, "success");
    }
  }, [isSuccess, data]);

  if (error) {
    console.error("Query Error:", error);
  }
  return {
    data,
    isLoading: isPending,
    isError: isError,
    error: error,
    isSuccess: isSuccess,
  };
};

const useGetGroupsByOrganizationId = (organizationId: string) => {
  const queryClient = useQueryClient();
  const { updateAllGroups } = useClientStore();

  const { data, error, isPending, isSuccess, isError } = useQuery({
    queryKey: ["allGroups", organizationId],
    queryFn: () =>
      organizationService.getGroupsByOrganizationId(organizationId),
    staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
    refetchOnWindowFocus: false, // refetching on window focus can be too aggressive, so it's turned off
    refetchOnReconnect: true, // refetch when the connection is restored
    refetchOnMount: true, // only refetch when parameters change, not every mount
    retry: 1, // retry failed queries once before throwing an error
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // use exponential backoff for retry delays
    enabled: !!organizationId, // only run the query if organizationId is available
  });

  useEffect(() => {
    if (isSuccess && data) {
      updateAllGroups(data, "success");
    }
  }, [isSuccess, data]);

  if (error) {
    console.error("Query Error:", error);
  }
  return {
    data,
    isLoading: isPending,
    isError: isError,
    error: error,
    isSuccess: isSuccess,
  };
};

export {
  useGetClientsByOrganizationId,
  useGetAllInvitations,
  useDoesOrganizationExist,
  useGetAllUsers,
  useInviteUser,
  useUpdateAuth0Organization,
  useGetOrganizationProfile,
  useGetOrganizationAuth0Data,
  useUpdateOrganization,
  useGetGroupsByOrganizationId,
};
function updateClientData(data: any) {
  throw new Error("Function not implemented.");
}
